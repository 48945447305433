import React, {useContext, useState} from "react";
import {navigate} from "gatsby";
import {extractRoleAndNameFromExternalUserId, extractStageAndTeamFromExternalMeetingId} from "debate-data/util";
import {DEBATE} from "debate-data";
import {useInterval} from "../hooks/interval";
import {useAuth} from "./auth";
import {SessionCookies, useStorage} from "../hooks/Store";
import {EndpointUrls} from "../config/api-endpoints"
import axios from "axios";

export const ApiContext = React.createContext(null)

export const DebateConnectionState = {
    START: "start",
    JOINING: "joining",
    STOPPING: "stopping",
    JOINED: "joined",
    ROLES_SET: "roles_set",
    FAILED: "failed",
    STOPPED: "stopped",
    DISCONNECTED: "disconnected"
}

const ReturnFrom = {
    Internal: "internal",
    External: "external"
}

class DebateServiceEndpoint {
    constructor(restApiUrl) {
        this.serviceUrl = `${restApiUrl}/debate`
    }

    getCreateDebateUrl() {
        return `${this.serviceUrl}`
    }

    getStartPrepUrl(debateId) {
        return `${this.serviceUrl}/${debateId}/startPrep`
    }

    getStartDebateUrl(debateId) {
        return `${this.serviceUrl}/${debateId}/startDebate`
    }

    getStopDebateUrl(debateId) {
        return `${this.serviceUrl}/${debateId}/stop`
    }

    getStartDebriefUrl(debateId) {
        return `${this.serviceUrl}/${debateId}/startDebrief`
    }
}

class UserServiceEndpoint {
    constructor(restApiUrl) {
        this.serviceUrl = `${restApiUrl}/user`
    }

    getCurrentDebateUrl(userId) {
        return `${this.serviceUrl}/${userId}/currentDebate`
    }
}

class SSOServiceEndpoint {
    constructor(restApiUrl) {
        this.serviceUrl = `${restApiUrl}/sso`
    }

    validate(payload, sig) {
        return `${this.serviceUrl}/validate?payload=${payload}&sig=${sig}`
    }
}

class BetaServiceEndpoint {
    constructor(publicApiUrl) {
        this.serviceUrl = `${publicApiUrl}`
    }

    getRequestBetaUrl() {
        return `${this.serviceUrl}/requestbeta`
    }

    getVerifyBetaEmailUrl() {
        return `${this.serviceUrl}/verifybetaemail`
    }
}

class WebsocketEndpoint {
    constructor(websocketApiUrl) {
        this.serviceUrl = `${websocketApiUrl}`
    }

    getUrl() {
        return `${this.serviceUrl}`
    }
}

const ForceRejoinOnPaths = {
    "lobby": true,
    "adjudicators": true,
    "preproom_first_government": true,
    "preproom_second_government": true,
    "preproom_first_opposition": true,
    "preproom_second_opposition": true,
    "debate": true,
    "debrief":  true
}

export function ApiProvider({ children }) {
    // TODO: rename
    const endpoint = React.useRef(null)
    const auth = useAuth()

    const hostName = document!==null ? new URL(document.URL).hostname : "localhost"

    // TODO: 'connected' is ambiguous - rename to websocketConnected
    const debateId = React.useRef(null)
    const meetingDetails = React.useRef({})
    const websocketMessageSubscription = React.useRef(new Map())
    const joinAttempts = React.useRef(0)
    const loginUserId = React.useRef("")
    const localVideoPaused = React.useRef(true)
    const websocketConnected = React.useRef(false)
    const stage = React.useRef(null)
    const team = React.useRef(null)
    const [debateConnectionState, setDebateConnectionState] = React.useState(DebateConnectionState.START)
    const [returnFrom, setReturnFrom] = useStorage(SessionCookies.RETURN_FROM, ReturnFrom.Internal)
    const [joinDebateIdOnReturn, setJoinDebateIdOnReturn] = useStorage(SessionCookies.DEBATE_ID, null)

    const debateServiceEndpoint = React.useRef(new DebateServiceEndpoint(EndpointUrls.restApiUrl))
    const userServiceEndpoint = React.useRef(new UserServiceEndpoint(EndpointUrls.restApiUrl))
    const ssoServiceEndpoint = React.useRef(new SSOServiceEndpoint(EndpointUrls.restApiUrl))
    const betaServiceEndpoint = React.useRef(new BetaServiceEndpoint(EndpointUrls.publicApiUrl))
    const websocketEndpoint = React.useRef(new WebsocketEndpoint(EndpointUrls.websocketApiUrl))

    React.useEffect(()=> {
        fetch('/config/api-endpoints.json')
            .then(response => {
                response.json().then(jsonValue => {

                    console.log('using API endpoints')
                    console.log(jsonValue)
                })
            })
    }, [])

    React.useEffect(()=>{
        if (auth.identity!=null) {
            connectToWebSocket()
        }
    }, [auth.identity])

    function setLocalVideoPaused(value) {
        localVideoPaused.current = value
    }

    function isLocalVideoPaused() {
        return localVideoPaused.current
    }

    React.useLayoutEffect(()=>{
        if (returnFrom===ReturnFrom.External) {
            const returningToPath = new URL(document.URL).pathname.replace(/\//g, '')

            const shouldReJoin = ForceRejoinOnPaths[returningToPath]

            console.log(`returning from external site [returningToPath=${returningToPath}, shouldRejoin=${shouldReJoin}]`)

            if (joinDebateIdOnReturn!==null && shouldReJoin) {
                navigate(`/rejoin?debateId=${joinDebateIdOnReturn}`)
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        window.addEventListener('unload', handleUnload)

        return () => {
            handleUnload().then(()=>{
            })

            window.removeEventListener('beforeunload', handleBeforeUnload)
            window.removeEventListener('unload', handleUnload)
        }
    }, [])

/*    function getApiEndpointConfig() {
        if (apiEndpoints===null) {
            fetch('/config/apiEndpoints.current.json')
                .then(response => {
                    response.json().then(jsonValue => {
                        apiEndpoints = jsonValue

                        console.log("using API endpoint.current configuration:")
                        console.log(apiEndpoints)
                    })
                })
                .then(data => console.log(data));
        }

        return apiEndpoints
    }*/

    const handleBeforeUnload = e => {
        setReturnFrom(ReturnFrom.External)

        if (debateId.current !== null) {
            setJoinDebateIdOnReturn(debateId.current)
        }
    }

    const handleUnload = async () => {
    }

    // TODO: can we implement this as a context or wrap the root element (in gatsby-browser)?
    //  this way it has to be added to each page
    useInterval(async (isCancelled) => {
        try {
            if (isCancelled()) return;

            if (websocketConnected.current) {
                console.log(`sending PING message to keep the connection alive`)
                ping()
            }
        } catch (err) {
            console.log(err);
        }
    }, 60000, true);

    const connectToWebSocket = () => {
        endpoint.current = new WebSocket(websocketEndpoint.current.getUrl())

        endpoint.current.onopen = (event) => {
            websocketConnected.current = true
            console.log(`connected to websocket`)
            console.log(event)
            console.log(endpoint.current)
        }

        endpoint.current.onclose = (event) => {
            console.log("websocket connection is closed")
            console.log(event)
        }

        endpoint.current.onerror = (event) => {
            console.error(`websocket error ${err}`)
            console.log(event)
        }

        endpoint.current.onmessage = websocketMessageReceived
    }

    function routeToStage() {
        console.log(`routing to stage [stage=${stage.current}, team=${team.current}]`)

        switch (stage.current) {
            case DEBATE.STAGE.LOBBY:
                navigate("/lobby")
                break
            case DEBATE.STAGE.PREP:
                switch (team.current) {
                    case DEBATE.TEAM.ADJUDICATORS:
                        navigate("/adjudicators")
                        break
                    case DEBATE.TEAM.FIRST_GOVERNMENT:
                        navigate("/preproom_first_government")
                        break
                    case DEBATE.TEAM.SECOND_GOVERNMENT:
                        navigate("/preproom_second_government")
                        break
                    case DEBATE.TEAM.FIRST_OPPOSITION:
                        navigate("/preproom_first_opposition")
                        break
                    case DEBATE.TEAM.SECOND_OPPOSITION:
                        navigate("/preproom_second_opposition")
                        break
                }
                break
            case DEBATE.STAGE.DEBATE:
                navigate("/debate")
                break
            case DEBATE.STAGE.DEBRIEF:
                switch (team.current) {
                    case DEBATE.TEAM.ALL:
                        navigate("/debrief")
                        break
                    case DEBATE.TEAM.ADJUDICATORS:
                        navigate("/adjudicators")
                        break
                }
                break
            default:
                navigate("/join")
        }
    }

    const websocketMessageReceived = (event) => {
        console.log("received websocket message:")

        const data = JSON.parse(event.data)
        console.log(data);

        if (data.message==="go_to_stage_failed") {
            console.log("join to debate failed, retrying")

            setTimeout(
                retryJoinDebate
                , 1000);
        } else if (data.message==="go_to_stage") {
            console.log("go_to_stage message received")
            console.log(data)

            setDebateConnectionState(DebateConnectionState.JOINED)

            const externalUserId = data.participantInfo.Attendee.ExternalUserId
            const externalMeetingId = data.meetingInfo.Meeting.ExternalMeetingId

            const [roleName, userName] = extractRoleAndNameFromExternalUserId(externalUserId)
            const [extractedStage, extractedTeam] = extractStageAndTeamFromExternalMeetingId(externalMeetingId)

            // An adjudicator is trying to join to the same (non-adjudicator) team room - skip it
            // TODO: should be prevented on the backend at first place, this is just a second line of defense here
            // TODO: this is an ugly hack - figure out something else -------------------------------------------------
            //  the ugly part is calling a setDebateConnectionState(DebateConnectionState.ROLES_SET) to turn off the
            //  backdrop and 'simulate' the join happened
            if (roleName===DEBATE.ROLE.ADJUDICATOR &&
                stage.current===DEBATE.STAGE.PREP &&
                team.current!==DEBATE.TEAM.ADJUDICATORS &&
                team.current===extractedTeam) {

                setDebateConnectionState(DebateConnectionState.ROLES_SET)

                return
            }
            // TODO: end of ugly hack ---------------------------------------------------------------------------------

            stage.current = data.stage
            team.current = extractedTeam

            switch (stage.current) {
                case DEBATE.STAGE.LOBBY:
                    console.log(`entering lobby`)

                    meetingDetails.current = {
                        meetingInfo: data.meetingInfo,
                        participantInfo: data.participantInfo,
                        debateId: debateId.current,
                        userName: userName,
                        roleName: roleName,
                        organizer: data.organizer,
                        motion: data.motion
                    }

                    break
                case DEBATE.STAGE.PREP:
                    if (team.current===DEBATE.TEAM.ADJUDICATORS) {
                        console.log(`entering adjudicator room`)

                        console.log(">>>> timer state")
                        console.log(data.timerState)

                        meetingDetails.current = {
                            meetingInfo: data.meetingInfo,
                            participantInfo: data.participantInfo,
                            debateId: debateId.current,
                            userName: userName,
                            roleName: roleName,
                            roleMap: data.roleMap,
                            organizer: data.organizer,
                            remainingDuration: data.remainingDuration,
                            totalDuration: data.totalDuration,
                            timerState: data.timerState,
                            motion: data.motion,
                            helpRequested: data.helpRequested,
                            prepDone: data.prepDone,
                            stage: data.stage,
                            adjudicatorLocation: data.adjudicatorLocation
                        }
                    } else {
                        console.log(`entering prep room`)

                        meetingDetails.current =  {
                            meetingInfo: data.meetingInfo,
                            participantInfo: data.participantInfo,
                            debateId: debateId.current,
                            userName: userName,
                            roleName: roleName,
                            roleMap: data.roleMap,
                            team: team.current,
                            organizer: data.organizer,
                            remainingDuration: data.remainingDuration,
                            totalDuration: data.totalDuration,
                            timerState: data.timerState,
                            motion: data.motion,
                            helpRequested: data.helpRequested,
                            prepDone: data.prepDone,
                            adjudicatorLocation: data.adjudicatorLocation
                        }
                    }
                    break
                case DEBATE.STAGE.DEBATE:
                    console.log(`entering debate`)

                    meetingDetails.current = {
                        meetingInfo: data.meetingInfo,
                        participantInfo: data.participantInfo,
                        debateId: debateId.current,
                        userName: userName,
                        roleName: roleName,
                        currentSpeaker: data.currentSpeaker,
                        protectedTime: data.protectedTime,
                        timerState: data.timerState,
                        roleMap: data.roleMap,
                        remainingDuration: data.remainingDuration,
                        totalDuration: data.totalDuration,
                        organizer: data.organizer,
                        motion: data.motion
                    }

                    break
                case DEBATE.STAGE.DEBRIEF:
                    console.log(`entering debrief`)

                    meetingDetails.current = {
                        meetingInfo: data.meetingInfo,
                        participantInfo: data.participantInfo,
                        debateId: debateId.current,
                        userName: userName,
                        roleName: roleName,
                        roleMap: data.roleMap,
                        organizer: data.organizer,
                        motion: data.motion,
                        stage: data.stage
                    }

                    break
                default:
                    console.error(`unexpected stage [${data.stage}]`)
            }

            routeToStage()
        } else if (data.message==="timer_event") {
            console.log(`timer event received`)
            console.log(data.event)
        } else if (data.message==="Internal server error") {
            console.log(`internal error`)
        } else if (data.message==="adjudicator_joined") {
            console.log(`adjudicator joined preproom [adjudicatorId=${data.adjudicatorId}, team=${data.team}]`)
        } else if (data.message==="set_role") {
            setDebateConnectionState(DebateConnectionState.ROLES_SET)
        } else if (data.message==="join_preproom_failed") {
            setDebateConnectionState(DebateConnectionState.ROLES_SET)
        }  else if (data.message==="debate_stopped") {
            setTimeout(()=>{
                navigate("/join")
                setDebateConnectionState(DebateConnectionState.STOPPED)
            }, 5000)
        } else if (data.message==="state_transition") {
            setDebateConnectionState(DebateConnectionState.JOINING)
        } else if (data.message==="cant_join_debate") {
            setDebateConnectionState(DebateConnectionState.FAILED)
        } else {
            console.log(`unexpected message - skipping`)
        }

        notifyWebsocketMessageSubscribers(data)
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const websocketRequestWithRetry = async (request, retries= 3, duration= 500) => {
        for (let i=0;i<retries;i++) {
            if (endpoint.current!==null && websocketConnected.current) {
                console.log(`executing websocket request [endpoint is ready: ${endpoint.current!==null}, connected: ${websocketConnected.current}, attempt: ${i}]`)
                return request()
            } else {
                console.log(`connection is not ready, waiting for websocket connection [endpoint is ready: ${endpoint.current!==null}, connected: ${websocketConnected.current}, attempt: ${i}]`)
                await sleep(duration)
            }
        }
    }

    const retryJoinDebate = () => {
        if (joinAttempts.current<3) {
            joinAttempts.current = joinAttempts.current+1

            const message = {
                action: "joindebate",
                auth: auth.identity,
                debateId: debateId.current
            }


            return websocketRequestWithRetry(() => {
                console.log(`join attempt [${joinAttempts.current}], sending message:`)
                console.log(message)

                return endpoint.current.send(JSON.stringify(message))
            }, 3, 1000)
        } else {
            console.log(`joining to debate ${debateId.current} is failed after retries`)
            setDebateConnectionState(DebateConnectionState.FAILED)

            notifyWebsocketMessageSubscribers({
                message: "cant_join_debate",
                debateId: debateId.current,
                reason: "DEBATE_NOT_FOUND"
            })
        }
    }

    const setRole = (user, role) => {
        const message = {
            action: "setrole",
            auth: auth.identity,
            debateId: debateId.current,
            role: role,
            user: user
        }

        console.log("send setRole message via websocket:")
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    const ping = () => {
        const message = {
            action: "ping",
            auth: auth.identity,
            debateId: debateId.current,
        }

        console.log("send ping message via websocket:")
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    const requestHelp = (flag) => {
        const message = {
            action: "requesthelp",
            auth: auth.identity,
            debateId: debateId.current,
            flag: flag
        }

        console.log("send requestHelp message via websocket:")
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    const prepDone = (flag) => {
        const message = {
            action: "prepdone",
            auth: auth.identity,
            debateId: debateId.current,
            flag: flag
        }

        console.log("send prepdone message via websocket:")
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    const setSpeakerRole = (role) => {
        const message = {
            action: "setspeakerrole",
            auth: auth.identity,
            debateId: debateId.current,
            role: role
        }

        console.log("send setSpeakerRole message via websocket:")
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    const getRoles = () => {
        const message = {
            action: "getroles",
            auth: auth.identity,
            debateId: debateId.current
        }

        console.log("send getRole message via websocket:")
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    const joinDebate = (newDebateId) => {
        console.log(`join debate [${newDebateId}]`)

        debateId.current = newDebateId
        joinAttempts.current = 0

        setDebateConnectionState(DebateConnectionState.JOINING)

        return retryJoinDebate()
    }

    const joinPrepRoom = (team) => {
        setDebateConnectionState(DebateConnectionState.JOINING)

        const message = {
            action: "joinpreproom",
            auth: auth.identity,
            debateId: debateId.current,
            team: team
        }

        console.log(`joining preproom, sending message:`)
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    const joinDebriefRoom = (team) => {
        const message = {
            action: "joindebriefroom",
            auth: auth.identity,
            debateId: debateId.current,
            team: team
        }

        console.log(`joining debrief room, sending message:`)
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    async function createDebate(motion) {
        console.log("create debate")

        if (auth==null) {
            console.log("error - authentication data is not available")
            return
        }

        const headers = {
            'Authorization': auth.identity
        }

        const data = {
            motion: motion,
            externalId: "1234"
        }

        try {
            const createDebateResponse = await axios.put(debateServiceEndpoint.current.getCreateDebateUrl(), data, {
                    headers: headers
                }
            )

            const responseData = createDebateResponse.data

            console.log(`debate created:`)
            console.log(createDebateResponse);

            if (responseData.status==="SUCCESS") {
                return joinDebate(responseData.data.debateId, auth.identity)
            } else {
                alert("debate limit exceeded")
            }
        } catch(error) {
        }
    }

    async function getCurrentDebate(userId) {
        console.log("get current debate")

        if (auth==null) {
            console.log("error - authentication data is not available")
            return
        }

        const headers = {
            'Authorization': auth.identity
        }

        if (userServiceEndpoint.current==null) {
            alert('wazz')
            return
        }

        return axios.get(userServiceEndpoint.current.getCurrentDebateUrl(userId), {
                headers: headers
            }
        )
    }

    function startStage(stage) {
        console.log(`start ${stage} stage for debate ${debateId.current}`)

        if (auth==null) {
            console.log("error - authentication data is not available")
            return
        }

        const headers = {
            'Authorization': auth.identity
        }

        let requestUrl=null

        switch (stage) {
            case DEBATE.STAGE.PREP:
                requestUrl = debateServiceEndpoint.current.getStartPrepUrl(debateId.current)
                break
            case DEBATE.STAGE.DEBATE:
                requestUrl = debateServiceEndpoint.current.getStartDebateUrl(debateId.current)
                break
            case DEBATE.STAGE.DEBRIEF:
                requestUrl = debateServiceEndpoint.current.getStartDebriefUrl(debateId.current)
                break
            default:
        }

        return new Promise((resolve, reject) => {
            if (requestUrl!=null) {
                axios.put(requestUrl, "", {
                        headers: headers
                    }
                )
                    .then(function (response) {
                        console.log(`started ${stage} stage`)
                        console.log(response);

                        setDebateConnectionState(DebateConnectionState.JOINING)

                        resolve()
                    })
                    .catch(function (error) {
                        console.log(`error while starting ${stage} stage`)
                        console.log(error);

                        setDebateConnectionState(DebateConnectionState.FAILED)

                        reject()
                    });
            } else {
                console.log(`can not start invalid stage [${stage}]`)
                reject()
            }
        })
    }

    function stopDebate() {
        return new Promise((resolve, reject) => {
            console.log(`stop debate ${debateId.current}`)

            if (auth==null) {
                console.log("error - authentication data is not available")
                return
            }

            const headers = {
                'Authorization': auth.identity
            }

            let requestUrl=null

            requestUrl = debateServiceEndpoint.current.getStopDebateUrl(debateId.current)

            if (requestUrl!=null) {
                axios.put(requestUrl, "", {
                        headers: headers
                    }
                )
                    .then(function (response) {
                        console.log(`stopped debate ${debateId.current}`)
                        console.log(response);

                        resolve(response)
                    })
                    .catch(function (error) {
                        console.log(`error while stopping debate ${debateId.current}`)
                        console.log(error);

                        reject()
                    });
            } else {
                console.log(`can't stop debate [${debateId.current}]`)

                reject()
            }
        })
    }

    function requestBeta(email) {
        return new Promise((resolve, reject) => {
            const requestUrl = betaServiceEndpoint.current.getRequestBetaUrl()

            const request = {
                "email": email
            }

            console.log(`requesting beta for email [${email}]`)

            axios.put(requestUrl, request).then(function (response, request) {
                console.log(`request beta response`)
                console.log(response);

                resolve(true)
            }).catch(function (error){
                console.log(`error while requesting beta`)
                console.log(error.response)

                resolve(false)
            })
        })
    }

    function verifyBetaEmail(email, verificationCode) {
        return new Promise((resolve, reject) => {
            const requestUrl = betaServiceEndpoint.current.getVerifyBetaEmailUrl()

            const request = {
                "email": email,
                "verificationCode": verificationCode
            }

            console.log(`verifying beta for email [${email}, ${verificationCode}]`)

            axios.put(requestUrl, request).then(function (response, request) {
                console.log(`beta email verification response`)
                console.log(response);

                resolve(true)
            }).catch(function (error) {
                console.log(`verifying beta email failed`)
                console.log(error.response)

                resolve(false)
            })
        })
    }

    const startTimer = () => {
        const message = {
            action: "starttimer",
            auth: auth.identity,
            debateId: debateId.current
        }

        console.log("send startTimer message via websocket:")
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    const pauseTimer = () => {
        const message = {
            action: "pausetimer",
            auth: auth.identity,
            debateId: debateId.current
        }

        console.log("send pauseTimer message via websocket:")
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    const stopTimer = () => {
        const message = {
            action: "stoptimer",
            auth: auth.identity,
            debateId: debateId.current
        }

        console.log("send stopTimer message via websocket:")
        console.log(message)

        return websocketRequestWithRetry(() => {
            endpoint.current.send(JSON.stringify(message))
        }, 3, 1000)
    }

    async function validateSSOLogin(payload, sig, identity) {
        console.log("validate sso login")

        if (auth==null) {
            console.log("error - authentication data is not available")
            return
        }

        const headers = {
            'Authorization': identity
        }

        return axios.get(ssoServiceEndpoint.current.validate(payload, sig), {
                headers: headers
            }
        )
    }

    function subscribeToWebsocketMessages(cb) {
        websocketMessageSubscription.current.set(cb, true)
    }

    function unsubscribeFromWebsocketMessages(cb) {
        websocketMessageSubscription.current.delete(cb)
    }

    function notifyWebsocketMessageSubscribers(message) {
        websocketMessageSubscription.current.forEach((value, key)=> {
            key(message)
        })
    }

    function getMeetingDetails() {
        return meetingDetails.current
    }

    function setLoginUserId(value) {
        loginUserId.current = value
    }

    function getLoginUserId() {
        return loginUserId.current
    }

    function getDebateId() {
        return debateId.current
    }

    function getCurrentStage() {
        return stage.current
    }

    const api = {
        joinDebateIdOnReturn,
        connectToWebSocket,
        createDebate,
        startStage,
        joinDebate,
        joinPrepRoom,
        joinDebriefRoom,
        setRole,
        ping,
        requestHelp,
        prepDone,
        setSpeakerRole,
        getRoles,
        startTimer,
        pauseTimer,
        stopTimer,
        subscribeToWebsocketMessages,
        unsubscribeFromWebsocketMessages,
        getMeetingDetails,
        setLoginUserId,
        getLoginUserId,
        setLocalVideoPaused,
        isLocalVideoPaused,
        requestBeta,
        verifyBetaEmail,
        getDebateId,
        debateConnectionState,
        websocketConnected,
        getCurrentDebate,
        routeToStage,
        getCurrentStage,
        validateSSOLogin,
        stopDebate
    }

    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
}

export function useApi() {
    return useContext(ApiContext);
}