import React, {useCallback, useEffect} from "react";
import MenuItem from '@mui/material/MenuItem';
import {AppBar, Avatar, Button, createTheme, IconButton, Snackbar, Toolbar, Tooltip} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import Pricing from "../pages/pricing";
import PropTypes from 'prop-types';
import {navigate} from "../../.cache/commonjs/gatsby-browser-entry";
import {useAuth} from "../context/auth";
import {DebateConnectionState, useApi} from "../context/api";
import {Alert, AlertTitle} from "@material-ui/lab";
import LoginDialog from "./LoginDialog";
import RegisterDialog from "./RegisterDialog";
import useMeasure from "react-use-measure";
import {Slide, useScrollTrigger} from "@mui/material";

const pages = [
    {id: "whatIsDebating", title: "What is debating?", variant: "text", menuColor: "", link: "/whatisdebate"},
    {id: "product", title: "Product", variant: "text", menuColor: "", link: "/product"},
    {id: "pricing", title: "Pricing", variant: "text", menuColor: "", link: "/pricing"},
    {id: "docs", title: "Docs", variant: "text", menuColor: "", link: "/doc"},
    {id: "debate", title: "Debate", variant: "contained", menuColor: "primary", link: "/join"}
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function DebateAppBar(props) {
    const auth = useAuth()
    const api = useApi()

    const receivedWebsocketMessage = useCallback((message) => {
        //console.log(`received websocket message [${videoTileId}, ${JSON.stringify(message)}, ${name}]`)

        if (message.message==="cant_join_debate") {
            setDebateId(message.debateId)
            setShowConnectionFailedMessage(true)
        }
    })

    const {children} = props

    if (!api || !auth) {
        return null
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const postLoginCallbackFunction = React.useRef(null)
    const [loginOpen, setLoginOpen] = React.useState(false)

    const params = new URLSearchParams(location?.search);
    const debateIdParam = params.get("debateId")
    const registrationTokenParam = params.get("registrationToken")

    const [registrationOpen, setRegistrationOpen] = React.useState(false)
    const [joiningDebate, setJoiningDebate] = React.useState(false)
    const registrationTokenProcessed = React.useRef(false)
    const [debateId, setDebateId] = React.useState(null)
    const [registrationToken, setRegistrationToken] = React.useState(null)
    const loginSubmitted = React.useRef(false)
    const [showConnectionFailedMessage, setShowConnectionFailedMessage] = React.useState(false);


    function onCloseRegisterDialog() {
        setRegistrationOpen(false)
        navigate("/")
    }

    if (debateIdParam!=null && debateId==null) {
        setDebateId(debateIdParam)
    }

    if (registrationTokenParam!=null && registrationToken==null) {
        setRegistrationToken(registrationTokenParam)
    }

    React.useEffect(() => {
        if (auth.identity != null) {
            if (!(debateId == null || joiningDebate)) {
                setJoiningDebate(true)

                api.joinDebate(debateId).then(result => {
                }).catch(err => {
                })
            }
        }

        if (debateId!=null) {
            if (!(loginOpen || loginSubmitted.current || joiningDebate) && auth.identity===null) {
                openLoginDialog()
            }
        }
    }, [auth.identity, debateId])

    useEffect(()=> {
        api.subscribeToWebsocketMessages(receivedWebsocketMessage)

        return () => {
            api.unsubscribeFromWebsocketMessages(receivedWebsocketMessage)
        }
    }, [receivedWebsocketMessage])

    if (registrationToken!=null) {
        if (!registrationOpen && !registrationTokenProcessed.current) {
            setRegistrationOpen(true)
            registrationTokenProcessed.current = true
        }
    }

    function loginClose(success) {
        if (loginOpen) {
            loginSubmitted.current = true
            setLoginOpen(false)
        }

        if (postLoginCallbackFunction.current) {
            postLoginCallbackFunction.current(success)
            postLoginCallbackFunction.current = false
        }
    }

    function openLoginDialog(postLoginCallback) {
        postLoginCallbackFunction.current = postLoginCallback

        if (!loginOpen) {
            loginSubmitted.current = false
            setLoginOpen(true)
        }
    }

    function postLoginCallback(success) {
        if (success) {
            // navigate("/join", { replace: true})
        }
    }

    function login() {
        openLoginDialog(postLoginCallback)
    }

    function logout() {
        auth.signOut()
        navigate("/")
    }

    function closeConnectionFailedMessage() {
        setShowConnectionFailedMessage(false)
    }

    let loginLogoutButton = <NavigationButton onClick={login} title={"Login"}/>
    let loggedInUserName = <></>

    if (auth?.identity) {
        loginLogoutButton = <NavigationButton onClick={logout} title={"Logout"} />
        loggedInUserName = <Box>
            <Typography variant="subtitle2" style={{color:"black"}}>
                logged in as <i>{auth.userName}</i>
            </Typography>
        </Box>
    }



    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (x) => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function NavigationButton(props) {
        const {link, title, onClick} = props

        function handleOnClick() {
            if (onClick) {
                onClick()
            }

            if (link) {
                navigate(link)
            }
        }

        return <Button {...props} onClick={handleOnClick}>
            {title}
        </Button>
    }

    function NavigationMenuItem(props) {
        const {link, title, onClick, id, color} = props

        function handleOnClick() {
            handleCloseNavMenu()

            if (onClick) {
                onClick()
            }

            if (link) {
                navigate(link)
            }
        }

        return (
            <MenuItem key={id} onClick={handleOnClick}>
                <Typography textAlign="center" color={color}>{title}</Typography>
            </MenuItem>
        )
    }

    function HideOnScroll(props) {
        const { children } = props;
        const trigger = useScrollTrigger({threshold:0});

        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        );
    }

    return (
        <AppBar position="fixed" color={"white"}>
            <Container maxWidth="xl">
                <Snackbar open={showConnectionFailedMessage} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={closeConnectionFailedMessage}>
                    <Alert onClose={closeConnectionFailedMessage} severity={"error"} sx={{ width: '100%' }} >
                        <AlertTitle>Unable to join</AlertTitle>Unable to join to debate {debateId}. Please make sure you
                        use a valid debate id.<br/><br/>Note that if the debate has already started, you will not be able to join even with
                        a valid debate id.<br/><br/>
                    </Alert>
                </Snackbar>
                <LoginDialog open={loginOpen} onClose={loginClose}/>
                <RegisterDialog onClose={onCloseRegisterDialog} open={registrationOpen} registrationToken={registrationToken}/>

                <Toolbar disableGutters>
                    <Box sx={{
                        mr: 5,
                        display: { xs: 'none', md: 'flex' }
                    }}>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                fontFamily: 'monospace',
                                fontWeight: 800,
                                letterSpacing: '1',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            debate.club
                        </Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <NavigationMenuItem id={page.id} title={page.title} link={page.link}/>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        mr: 2
                    }}>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '1',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            debate.club
                        </Typography>
                    </Box>
                    <Box justifyContent={"flex-end"} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'}}}>
                            {pages.map((page) => (
                                <NavigationButton
                                    color={"emphasized"}
                                    key={page.id}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    variant={page.variant}
                                    disableElevation
                                    link={page.link}
                                    title={page.title}
                                />
                            ))}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        {loginLogoutButton}
                        <Box display={"none"}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
            {children}
        </AppBar>
    );
}
export default DebateAppBar;